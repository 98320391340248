/**
 * @file The globally accessible instance of the paymentJourneyMachine.
 *
 * The top level state machine handles the user journey across the entire
 * payment process.
 */

import { paymentJourneyMachine } from '@/sbgl/payments/machines/paymentJourneyMachine';
import { acceptHMRUpdate, defineStore } from 'pinia';
import useMachineStore from 'pinia-xstate';

/**
 * Pinia store...
 *
 * @see https://pinia.vuejs.org/
 */
export const usePaymentStore = defineStore(
  'payment',
  useMachineStore(paymentJourneyMachine, { devTools: true })
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePaymentStore, import.meta.hot));
}
