import type { AppConfig } from '.';

/**
 * Get a brand settings object with some generic defaults.
 */
export default function getDefaultConfig(): AppConfig {
  return {
    maintenance: true,
    siteUrl: '',
    apiUrl: '',
    stripePublicKey: '',

    company: {
      brand: 'Somerset Bridge Insurance Services Limited',
      logo: '/img/logo-sbl.svg',
      favicons: '/favicons/sbl',
      products: {},
      contactUrl: 'https://somersetbridgeinsurance.co.uk/contact-us/',
      contactNumbers: {},
      openingHours: {
        default: {},
      },
    },

    content: {
      notFound: {
        heading: 'You followed an invalid URL',
        body: "There's nothing else to show on this page, sorry!",
      },
      postPay: '',
      footer: {
        menu: [],
        smallPrint: '',
      },
    },
  };
}
