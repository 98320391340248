<template>
  <p>
    <small v-html="t('footer.small_print')" />
  </p>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
