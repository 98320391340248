import { PaymentRequestDetails, PaymentType } from '@/sbgl/payments';
import { PaymentValidationError } from '@/sbgl/payments/errors';
import {
  extractLiveChatPaymentUrl,
  extractOsbalPaymentUrl,
  extractOsdebtPaymentUrl,
  extractRenewalPaymentUrl,
} from '@/sbgl/payments/urlValidation';
import { LocationQuery } from 'vue-router';
import { ActorRef, assign, createMachine, raise, sendTo, spawn } from 'xstate';
import { router } from '../../../router';
import { ApiEvent, v3PaymentMachine } from './v3PaymentMachine';

export interface PaymentContext {
  stripeMachineRef?: ActorRef<ApiEvent>;

  brandKey?: string;
  paymentType?: PaymentType;
  urlQueryParameters?: LocationQuery;
  paymentRequestData?: PaymentRequestDetails;

  errors: Error[];
}

export type PaymentEvent =
  | {
      type: 'INIT_LOADING';
      brandKey: string;
    }
  | { type: 'INIT_API_READY' }
  | {
      type: 'SETUP_NEW_PAYMENT_FROM_URL';
      paymentType: PaymentType;
      urlQueryParameters: LocationQuery;
    }
  | {
      type: 'SETUP_EXISTING_PAYMENT';
      paymentType: PaymentType;
      paymentIntent: string;
      paymentIntentClientSecret: string;
      redirectStatus: string;
      // urlQueryParameters: LocationQuery;
    }
  | {
      type: 'PAYMENT_FORM_LOADING';
    }
  | {
      type: 'PAYMENT_FORM_READY';
    }
  | {
      type: 'PAYMENT_PROCESSING';
    }
  | {
      type: 'PAYMENT_SUCCEEDED';
      paymentIntent?: string;
    }
  | {
      type: 'PAYMENT_FAILED';
      paymentIntent?: string;
    }
  | {
      type: 'PAYMENT_ERROR';
      error?: Error;
    };

/**
 * An XState state machine describing the high level payment journey.
 *
 * This machine is responsible for setting up the internal API handler machine
 * and redirecting the customer to the appropriate pages when applicable.
 *
 * @see https://xstate.js.org/docs/
 */
export const paymentJourneyMachine = createMachine<
  PaymentContext,
  PaymentEvent
>(
  {
    id: 'payment',
    initial: 'idle',
    predictableActionArguments: true,
    strict: true,

    context: {
      stripeMachineRef: undefined,

      brandKey: undefined,
      paymentType: undefined,
      urlQueryParameters: undefined,
      paymentRequestData: undefined,

      errors: [],
    },

    on: {
      PAYMENT_ERROR: {
        actions: [
          assign({
            errors: (context, event) => context.errors.concat(event.error),
          }),
        ],
      },

      PAYMENT_FAILED: {
        target: 'failure',
      },
    },

    states: {
      // Waiting for brand config to be loaded...
      idle: {
        tags: ['loading'],

        on: {
          INIT_LOADING: {
            target: 'loading',
            actions: [
              assign({
                brandKey: (context, event) => event.brandKey,
              }),
            ],
          },
        },
      },

      // Setting up the internal Stripe handler machine...
      loading: {
        tags: ['loading'],

        entry: [
          assign({
            stripeMachineRef: (context) => {
              const { brandKey } = context;

              return spawn(
                v3PaymentMachine.withContext({
                  ...v3PaymentMachine.context,
                  brandKey,
                }),
                'v3StripePayment'
              );
            },
          }),
        ],

        on: {
          INIT_API_READY: {
            target: 'ready',
          },
        },
      },

      // Ready to start or resume a payment...
      ready: {
        on: {
          SETUP_NEW_PAYMENT_FROM_URL: {
            target: 'extractingUrlParameters',
            actions: [
              assign({
                paymentType: (context, event) => event.paymentType,
                urlQueryParameters: (context, event) =>
                  event.urlQueryParameters,
              }),
            ],
          },
          SETUP_EXISTING_PAYMENT: {
            target: 'processing',
            actions: [
              assign({
                paymentType: (context, event) => event.paymentType,
              }),
              sendTo('v3StripePayment', (context, event) => {
                const {
                  paymentType,
                  paymentIntent,
                  paymentIntentClientSecret,
                  redirectStatus,
                } = event;

                return {
                  type: 'STRIPE_SETUP_EXISTING_PAYMENT',
                  paymentType,
                  paymentIntent,
                  paymentIntentClientSecret,
                  redirectStatus,
                };
              }),
            ],
          },
        },
      },

      // Validating and extracting details contained in URL...
      extractingUrlParameters: {
        tags: ['loading'],

        invoke: {
          src: 'extractUrlData',
          onDone: [
            {
              target: 'settingUpPayment',
              actions: [
                assign({
                  paymentRequestData: (context, event) => event.data,
                }),
              ],
            },
          ],
          onError: [
            {
              actions: [
                raise((context, event) => ({
                  type: 'PAYMENT_ERROR',
                  error: event.data,
                })),
                raise({
                  type: 'PAYMENT_FAILED',
                  data: '',
                }),
              ],
            },
          ],
        },
      },

      // Perform initial actions before showing the loading form...
      settingUpPayment: {
        tags: ['loading'],

        entry: [
          sendTo('v3StripePayment', (context, event) => {
            const { paymentRequestData } = context;

            return { type: 'STRIPE_SETUP_NEW_PAYMENT', paymentRequestData };
          }),
        ],

        on: {
          PAYMENT_FORM_LOADING: {
            target: 'loadingPaymentForm',
          },
        },
      },

      // Setting up the payment form behind a loading spinner...
      loadingPaymentForm: {
        tags: ['loading'],

        entry: [
          {
            type: 'redirect',
            replace: true,
            location: {
              name: 'payment',
            },
          },
        ],

        on: {
          PAYMENT_FORM_READY: {
            target: 'customerEnteringDetails',
          },
        },
      },

      // The payment form is ready for user interaction...
      customerEnteringDetails: {
        on: {
          PAYMENT_PROCESSING: {
            target: 'processing',
          },
        },
      },

      // Attempting to capture the payment...
      processing: {
        tags: ['loading'],

        on: {
          PAYMENT_SUCCEEDED: {
            target: 'success',
          },
        },
      },

      // The payment was captured and the customer can close the site...
      success: {
        type: 'final',

        entry: [
          {
            type: 'redirect',
            replace: true,
            location: {
              name: 'complete',
            },
          },
        ],
      },

      // An unrecoverable error occurred...
      failure: {
        type: 'final',

        entry: [
          {
            type: 'redirect',
            replace: true,
            location: {
              name: 'error',
            },
          },
        ],
      },
    },
  },

  {
    actions: {
      // FIXME: Using the router inside the machine is a bit weird.
      //
      // To fully decouple this machine from the rest of the codebase, we could
      // pass in a redirect service or something from App.vue...
      //
      // But maybe that's too complicated?
      redirect: (context, event, { action }) => {
        const {
          replace = false,
          location = { path: '/test' },
        }: { location: LocationQuery; replace: boolean } = action;

        console.log('Navigating...', { location, replace });

        if (replace) {
          router.replace(location);
        } else {
          router.push(location);
        }
      },
    },

    services: {
      extractUrlData: async (context, event) => {
        const { paymentType, urlQueryParameters } = context;

        if (urlQueryParameters) {
          switch (paymentType) {
            case PaymentType.Osbal:
              return extractOsbalPaymentUrl(urlQueryParameters);

            case PaymentType.Osdebt:
              return extractOsdebtPaymentUrl(urlQueryParameters);

            case PaymentType.Renewal:
              return extractRenewalPaymentUrl(urlQueryParameters);

            case PaymentType.LiveChat:
              return extractLiveChatPaymentUrl(urlQueryParameters);
          }
        }

        // TODO: is not a typical validation error and does not have an error bag
        throw new PaymentValidationError({
          messages: ['Unexpected payment type'],
        });
      },
    },
  }
);
