/**
 * @file Handles loading of brand specific details and switching between brands.
 *
 * Brands provide settings and themes.
 */

import { ThemeConfig } from '@/sbgl/theme';
import { getCssVarsFromStyles } from '@/sbgl/theme/styles';
import { trailingSlashIt } from '@/sbgl/utils';
import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useBrandsStore } from './brands';

/**
 * Pinia store...
 *
 * @see https://pinia.vuejs.org/
 */
export const useThemeStore = defineStore('theme', () => {
  const { active: activeBrand, brandConfig } = storeToRefs(useBrandsStore());

  const styles = computed((): ThemeConfig | null => {
    return activeBrand.value?.theme ?? null;
  });

  const stylesAsCssProperties = computed(() => {
    return getCssVarsFromStyles(styles.value);
  });

  const fontImportUrls = computed(() => {
    return [...(styles.value?.typography.fontImportUrls ?? [])];
  });

  const faviconsUrl = computed((): string => {
    return trailingSlashIt(brandConfig.value?.company.favicons ?? '');
  });

  const headMetaAttrs = computed((): object[] => {
    return [
      {
        name: 'msapplication-TileColor',
        content: styles.value?.colors.primary,
      },
      {
        name: 'msapplication-config',
        content: `${faviconsUrl.value}browserconfig.xml`,
      },
      {
        name: 'theme-color',
        content: styles.value?.colors.primary,
      },
    ];
  });

  const headFaviconLinks = computed((): object[] => {
    return [
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: `${faviconsUrl.value}apple-touch-icon.png`,
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: `${faviconsUrl.value}favicon-32x32.png`,
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: `${faviconsUrl.value}favicon-16x16.png`,
      },
      {
        rel: 'manifest',
        href: `${faviconsUrl.value}site.webmanifest`,
      },
      {
        rel: 'mask-icon',
        href: `${faviconsUrl.value}safari-pinned-tab.svg`,
        color: styles.value?.colors.primary,
      },
      {
        rel: 'shortcut icon',
        href: `${faviconsUrl.value}favicon.ico`,
      },
    ];
  });

  return {
    styles,
    stylesAsCssProperties,
    faviconsUrl,
    fontImportUrls,
    headMetaAttrs,
    headFaviconLinks,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useThemeStore, import.meta.hot));
}
