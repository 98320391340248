<template>
  <div class="test-banner">
    <div class="test-banner__title">
      <span>Test mode</span>
    </div>

    <ul class="test-banner__cards">
      <li>
        Visa:
        <strong>4242424242424242</strong>
      </li>
      <li>
        3DS:
        <strong>4000002760003184</strong>
      </li>
      <li>
        <a :href="testCardsUrl" target="_blank"> More test cards &hellip; </a>
      </li>
    </ul>

    <ul class="test-banner__debug">
      <li>
        <label>
          Debug State <input v-model="enableStateDebug" type="checkbox" />
        </label>
      </li>
      <li v-if="enableStateDebug">
        State: <strong>{{ currentState }}</strong>
      </li>
      <li>
        Version: <strong>{{ buildVersion }}</strong>
      </li>
      <li>
        Built: <strong>{{ buildDate.toLocaleString() }}</strong>
      </li>
      <li>
        <label>
          Brand:
          <BrandSwitcher />
        </label>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import BrandSwitcher from '@/components/header/BrandSwitcher.vue';
import { useDebugStore } from '@/stores/debug';
import { usePaymentStore } from '@/stores/payment';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const { buildVersion, buildDate, enableStateDebug } =
  storeToRefs(useDebugStore());
const { state } = storeToRefs(usePaymentStore());

const currentState = computed(() => state.value.value);

const testCardsUrl = 'https://stripe.com/docs/testing#use-test-cards';
</script>

<style lang="scss">
.test-banner {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  background: var(--colors-success);
  color: var(--colors-light);
  font-size: var(--typography-font-size-small);
  box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1);
  z-index: 50;
}

.test-banner a {
  &,
  &:active,
  &:visited {
    color: var(--colors-white);
  }
}

.test-banner > * {
  display: flex;
  flex-wrap: inherit;
  align-items: inherit;
  overflow: hidden;
  margin: 0.5rem;
}

.test-banner > ul {
  list-style: none;
  padding: 0;
  gap: 1.5rem;
}

.test-banner__title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--typography-font-size-large);
  font-family: var(--typography-font-family-heading);
}

.test-banner__cards {
  font-size: 0.8em;
}

.test-banner__debug {
  margin-left: auto;
}
</style>
