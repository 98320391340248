/**
 * @file Some Stripe specific getters and utilities.
 *
 * Uses brand configuration.
 */

import { PaymentType } from '@/sbgl/payments';
import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useBrandsStore } from './brands';
import { useDebugStore } from './debug';

/**
 * Pinia store...
 *
 * @see https://pinia.vuejs.org/
 */
export const useStripeStore = defineStore('stripe', () => {
  const { brandConfig } = storeToRefs(useBrandsStore());
  const { testing } = storeToRefs(useDebugStore());

  // The public key used to set up the Stripe browser SDK.
  const publishableApiKey = computed((): string | undefined => {
    if (!brandConfig.value) {
      return;
    }

    const { stripePublicKey } = brandConfig.value;

    return stripePublicKey;
  });

  /**
   * The page the customer should return to after being redirected off-site.
   */
  function getReturnUrl(paymentType: PaymentType): string | undefined {
    if (!brandConfig.value) {
      return;
    }

    const { siteUrl } = brandConfig.value;

    const redirectUrl = new URL(`payment/resume/${paymentType}`, siteUrl);

    return redirectUrl.toString();
  }

  return {
    publishableApiKey,
    getReturnUrl,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStripeStore, import.meta.hot));
}
