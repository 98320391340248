/**
 * @file Some miscellaneous utility functions.
 */

import {
  camelCase,
  isPlainObject,
  kebabCase,
  mapKeys,
  mapValues,
} from 'lodash-es';

/**
 * Perform a deep map over an object.
 */
function deep(obj, mapper) {
  return mapper(
    mapValues(obj, (v) => {
      return isPlainObject(v) ? deep(v, mapper) : v;
    })
  );
}

/**
 * Loops through an object or an array and converts the keys to camelCase
 *
 * @see isObject
 * @see isArray
 * @see toCamelCase
 *
 * @param {Object|Array} object - The object or array we are processing
 * @returns {Object} The converted object with camelCased keys
 */
export function keysToCamelCase(object) {
  return deep(object, (x) => {
    return mapKeys(x, (val, key) => {
      return camelCase(key);
    });
  });
}

/**
 * Loops through an object or an array and converts the keys from camelCase to kebab-case
 *
 * @see isObject
 * @see isArray
 * @see toCamelCase
 *
 * @param {Object|Array} object - The object or array we are processing
 * @returns {Object} The converted object with kebab-cased keys
 */
export function keysToKebabCase(object) {
  return deep(object, (x) => {
    return mapKeys(x, (val, key) => {
      return kebabCase(key);
    });
  });
}

/**
 * Checks if the object has a particular value and returns its key
 *
 * @param {Object} object - The object we are searching inside
 * @param {value} value - The value of the key we are looking for
 * @returns {string} The found key
 */
export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

/**
 * Removes trailing slash from a string.
 *
 * @param {string} string - The initial string value.
 * @returns {string} The string with trailing slashes removed.
 */
export function untrailingSlashIt(string) {
  return string.replace(/\/$/, '');
}

/**
 * Adds trailing slash to a string if it doesn't already exist.
 *
 * @see untrailingSlashIt
 * @param {string} string - The initial string value.
 * @returns {string} The string with a trailing slash at the end.
 */
export function trailingSlashIt(string) {
  return `${untrailingSlashIt(string)}/`;
}
