<template>
  <div class="container">
    <slot />
  </div>
</template>

<style lang="scss">
.container {
  display: flex;
  flex-direction: column;

  max-width: var(--site-container-max-width);

  margin: 0 auto;
  padding: 0 var(--site-container-padding);

  @media screen and (max-width: 992px) {
    max-width: 768px;
  }

  @media screen and (max-width: 768px) {
    max-width: 576px;
    padding: 0 20px;
  }
}
</style>
