/**
 * @file Handles loading of brand specific details and switchBranding between brands.
 *
 * Brands provide settings and themes.
 */

import { getKnownBrandKeys } from '@/sbgl/brand';
import {
  AppConfig,
  AppEnvironments,
  fetchBrandConfig,
  fetchBrandThemeStyles,
  getDefaultConfig,
} from '@/sbgl/payments/config';
import { ThemeConfig } from '@/sbgl/theme';
import { defaultsDeep } from 'lodash-es';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';

export interface LoadedBrandData {
  brandKey: string;
  configBase: AppConfig;
  configEnvironments: AppEnvironments;
  theme: ThemeConfig;
}

/**
 * Pinia store...
 *
 * @see https://pinia.vuejs.org/
 */
export const useBrandsStore = defineStore('brands', () => {
  const activeKey = ref<string>();

  // Brands that can be loaded.
  const availableKeys = ref<string[]>(getKnownBrandKeys());

  // Loaded brands by key.
  const loaded = ref<{ [key: string]: LoadedBrandData }>({});

  // The active brand's details.
  const active = computed((): LoadedBrandData | null => {
    if (!activeKey.value || !loaded.value.hasOwnProperty(activeKey.value)) {
      return null;
    }

    return loaded.value[activeKey.value];
  });

  // Brand config with overrides for the current environment.
  const brandConfig = computed((): AppConfig | null => {
    if (!active.value) {
      return null;
    }

    const mode = import.meta.env.MODE;

    const defaultConfig = getDefaultConfig();
    const { configBase, configEnvironments } = active.value;

    return defaultsDeep(
      {},
      configEnvironments[mode],
      configBase,
      defaultConfig
    );
  });

  /**
   * Change the active brand, fetches remote data if necessary.
   */
  async function switchBrand(brandKey: string) {
    return loadBrand(brandKey).then((brand) => {
      activeKey.value = brandKey;

      return brand;
    });
  }

  /**
   * Load a brand's configuration and theme overrides.
   */
  async function loadBrand(brandKey: string): Promise<LoadedBrandData> {
    // Skip if already loaded.
    if (!loaded.value[brandKey]) {
      // Make requests...
      const brandData = await Promise.all([
        fetchBrandConfig(brandKey),
        fetchBrandThemeStyles(brandKey),
      ]).then(([{ configBase, configEnvironments }, theme]) => {
        return {
          brandKey,
          configBase,
          configEnvironments,
          theme,
        };
      });

      // Update store.
      loaded.value[brandKey] = brandData;
    }

    return loaded.value[brandKey];
  }

  return {
    activeKey,
    availableKeys,
    loaded,
    active,
    brandConfig,

    switchBrand,
    loadBrand,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBrandsStore, import.meta.hot));
}
