import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarAlt,
  faPaste,
  faPoundSign,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import { i18n } from './locale';
import { router } from './router';
import { setupSentryVue } from './sentry';
import { stateDebugPlugin } from './stateDebugPlugin';

// Setup Vue...
const app = createApp(App);

// Setup Stripe browser SDK.
if (import.meta.env.MODE !== 'production') {
  setupSentryVue(app);
}

app.use(router);
app.use(i18n);

// Add Font Awesome.
library.add(faCalendarAlt, faPoundSign, faPaste);
app.component('FontAwesomeIcon', FontAwesomeIcon);

// Add Pinia and setup data stores.
const pinia = createPinia();
app.use(pinia);

// Add XState debugging tools.
app.use(stateDebugPlugin);

app.mount('#app');
