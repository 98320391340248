<template>
  <Container>
    <Card>
      <div class="error">
        <h1>{{ t('error.maintenance.heading') }}</h1>

        <div>
          <p v-html="t('error.maintenance.message', { contactUrl })" />

          <p v-html="t('error.maintenance.message_extra', { contactUrl })" />
        </div>
      </div>
    </Card>
  </Container>
</template>

<script setup lang="ts">
import Card from '@/components/base/Card.vue';
import Container from '@/components/base/Container.vue';
import { useContentStore } from '@/stores/content';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { contactUrl } = storeToRefs(useContentStore());
</script>
