<template>
  <div id="loader">
    <div class="lds-ring">
      <div />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#loader {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: visible;
  z-index: 500;
  background: rgba(0, 0, 0, 0.2);

  p {
    color: var(--colors-primary);
    font-size: 1em;
    margin: 0;
  }
}

.lds-ring {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 50%;
  width: 75px;
  height: 75px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 75px;
  height: 75px;
  border: 6px solid var(--colors-primary);
  border-radius: 50%;
  animation: lds-ring 0.8s linear infinite;
  border-color: var(--colors-primary) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-ring-reverse {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
