<template>
  <p class="copyright">
    {{ t('footer.copyright', { brandName, year }) }}
  </p>
</template>

<script setup lang="ts">
import { useContentStore } from '@/stores/content';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { brandName } = storeToRefs(useContentStore());

// The full, current year in 4 digits (e.g. 2019)
const year = computed(() => {
  const date = new Date();
  return date.getFullYear();
});
</script>
