/**
 * @file Details about the customer and their payment.
 *
 * Provides some getters for easy access of info in the payment state machine
 * context.
 */

import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { computed } from 'vue';
import { usePaymentStore } from './payment';

/**
 * Pinia store...
 *
 * @see https://pinia.vuejs.org/
 */
export const useCustomerStore = defineStore('customer', () => {
  // Get the payment state machine so we can provide some friendly getters...
  const { state: paymentState } = storeToRefs(usePaymentStore());

  // Payment request data extracted from the entry URL.
  const paymentRequest = computed(
    () => paymentState.value.context.paymentRequestData
  );

  // The type of the this payment.
  //
  // Use payment request data object, falling back to initially set payment type.
  const paymentType = computed(
    () =>
      paymentRequest.value?.paymentType ??
      paymentState.value.context.paymentType
  );

  // The payment amount as a integer number of pennies.
  const amount = computed(() => paymentRequest.value?.amount);

  // The product for this payment, if applicable.
  const activeProduct = computed(() => paymentRequest.value?.fields?.product);

  // The customer's policy reference, if applicable.
  const policyRef = computed(() => paymentRequest.value?.fields?.ref);

  // The price/amount formatted for humans.
  const displayAmount = computed(() => {
    const pennies = amount.value;

    if (pennies && typeof pennies === 'number') {
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      });

      return formatter.format(pennies / 100);
    }

    return '';
  });

  // Any errors encountered while processing the payment.
  const errors = computed(() => paymentState.value.context.errors);

  return {
    paymentRequest,
    paymentType,
    amount,
    displayAmount,
    activeProduct,
    policyRef,

    errors,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCustomerStore, import.meta.hot));
}
