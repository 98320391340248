/**
 * @file Handles loading of brand specific details and switching between brands.
 *
 * Brands provide settings and themes.
 */

import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useBrandsStore } from './brands';
import { useCustomerStore } from './customer';

/**
 * Pinia store...
 *
 * @see https://pinia.vuejs.org/
 */
export const useContentStore = defineStore('content', () => {
  const { brandConfig } = storeToRefs(useBrandsStore());
  const { activeProduct } = storeToRefs(useCustomerStore());

  const brandName = computed(() => {
    return brandConfig.value?.company.brand;
  });

  const brandLogo = computed(() => {
    return brandConfig.value?.company.logo;
  });

  const footerMenuItems = computed(() => {
    return brandConfig.value?.content.footer.menu;
  });

  const products = computed(() => {
    return brandConfig.value?.company.products ?? {};
  });

  const contactUrl = computed(() => {
    return brandConfig.value?.company.contactUrl;
  });

  const contactNumbers = computed(() => {
    return brandConfig.value?.company.contactNumbers ?? {};
  });

  const openingHours = computed(() => {
    return brandConfig.value?.company.openingHours ?? {};
  });

  // A contact number for the product that the customer is making a payment for.
  //
  // Fall back to a default number if available.
  const relevantContactNumber = computed(() => {
    const product = activeProduct.value;
    const numbers = contactNumbers.value;

    if (numbers) {
      // first tries to find contact number specific to product
      if (product && numbers[product]) {
        return numbers[product];
      }

      // if that fails, look for default contact number
      if (numbers['default']) {
        return numbers['default'];
      }
    }

    return null;
  });

  // Opening hours for the product that the customer is making a payment for.
  //
  // Fall back to default opening times if available.
  const relevantOpeningHours = computed(() => {
    const product = activeProduct.value;
    const hours = openingHours.value;

    // Find matching product for the customer product key, e.g. PC = car
    if (hours) {
      // first tries to set opening hours specific to product
      if (hours[product]) {
        return hours[product];
      }

      // if that fails, look for default opening hours
      if (hours['default']) {
        return hours['default'];
      }
    }

    return null;
  });

  return {
    brandName,
    brandLogo,
    footerMenuItems,
    products,
    openingHours,
    contactUrl,
    contactNumbers,
    relevantContactNumber,
    relevantOpeningHours,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useContentStore, import.meta.hot));
}
