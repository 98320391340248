<template>
  <select :value="activeKey" @change="updateBrand">
    <option v-for="brand in availableKeys" :key="brand" :value="brand">
      {{ brand }}
    </option>
  </select>
</template>

<script setup lang="ts">
import { useBrandsStore } from '@/stores/brands';
import { storeToRefs } from 'pinia';

const { activeKey, availableKeys } = storeToRefs(useBrandsStore());

//
function updateBrand(event) {
  useBrandsStore().switchBrand(event.target.value);
}
</script>
