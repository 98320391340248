import type { ThemeConfig } from '.';

export default function getDefaultTheme(): ThemeConfig {
  return {
    colors: {
      primary: '#009b77',
      secondary: '#db0a5a',
      tertiary: '#ffc630',
      success: '#009b77',
      info: '#ffc800',
      warning: '#ffc630',
      danger: '#ff585e',
      light: '#f6f6f6',
      mid: '#ccc',
      dark: '#000',
      white: '#fff',
      black: '#000',
      body: '#000',
      alt: '#13294b',
    },
    site: {
      spacing: '1rem',
      containerMaxWidth: '1100px',
      containerPadding: '2rem',
      borderRadius: '3px',
      bodyBackgroundColor: '#fff',
    },
    breakpoints: {
      xs: '0px',
      sm: '576px',
      md: '768px',
      lg: '992px',
      xl: '1200',
    },
    typography: {
      fontSize: '1.125em',
      fontSizeLarge: '1.1rem',
      fontSizeSmall: '0.9rem',
      fontSizeHeading: '1.1rem',
      fontFamily: "'Helvetica Neue','Arial Nova',Helvetica,Arial,sans-serif",
      fontFamilyHeading: "Times,'Times New Roman',serif",
      fontImportUrls: [],
    },
    nav: {
      backgroundColor: '#fff',
      color: '#000',
      linkColor: '#009b77',
      linkHoverColor: '#009b77',
    },
    footer: {
      backgroundColor: '#fff',
      color: '#000',
      linkColor: '#009b77',
      linkHoverColor: '#009b77',
    },
    button: {
      color: '#fff',
      hoverColor: '#fff',
      backgroundColor: '#009b77',
      hoverBackgroundColor: '#009b77',
      padding: '15px',
    },
    input: {
      color: '#13294b',
      backgroundColor: '#fff',
      placeholderColor: '#ccc',
      padding: '15px',
      fontSize: '1em',
      border: '1px solid #ccc',
    },
    links: {
      color: '#009b77',
      hoverColor: '#009b77',
      textDecoration: 'none',
      hoverTextDecoration: 'underline',
    },
  };
}
