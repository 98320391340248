import { ThemeConfig, getDefaultTheme } from '@/sbgl/theme';
import { keysToCamelCase } from '@/sbgl/utils';
import axios from 'axios';
import { defaultsDeep } from 'lodash-es';
import getDefaultConfig from './defaults';

export { getDefaultConfig };

export interface MenuItem {
  title: string;
  href: string;
}

export interface AppEnvironments {
  [key: string]: Partial<AppConfig>;
}

export interface AppConfig {
  maintenance: boolean;
  siteUrl: string;
  apiUrl: string;
  stripePublicKey: string;

  company: {
    brand: string;
    logo: string;
    favicons: string;
    products: object;
    contactUrl: string;
    contactNumbers: object;
    openingHours: object;
  };

  content: {
    notFound: {
      heading: string;
      body: string;
    };
    postPay: string;
    footer: {
      menu: MenuItem[];
      smallPrint: string;
    };
  };
}

/**
 * Get a brand theme styles object with some generic defaults.
 */
export function getDefaultStyles(): ThemeConfig {
  return getDefaultTheme();
}

//
export function getBrandConfigUrl(brandKey: string): string {
  return `/config/settings-${brandKey}.json`;
}

export function getThemeConfigUrl(brandKey: string): string {
  return `/config/theme-${brandKey}.json`;
}

/**
 * Get the settings object for a brand.
 */
export async function fetchBrandConfig(
  brandKey: string
): Promise<{ configBase: AppConfig; configEnvironments: AppEnvironments }> {
  const url = getBrandConfigUrl(brandKey);

  const brandConfig = await axios({
    url,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then((response) => response.data);

  const { environments: configEnvironments, ...configBase } =
    keysToCamelCase(brandConfig);

  return {
    configBase,
    configEnvironments,
  };
}

/**
 * Get the theme styles object for a brand.
 */
export async function fetchBrandThemeStyles(
  brandKey: string
): Promise<ThemeConfig> {
  const url = getThemeConfigUrl(brandKey);

  const defaultStyles = getDefaultStyles();
  const themeStyles = await axios({
    url,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then((response) => response.data);

  return defaultsDeep(keysToCamelCase(themeStyles), defaultStyles);
}
