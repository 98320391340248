//
//
//

export type StripePaymentMethod = string;
export type StripePaymentIntent = string;

//
//
//
export enum PaymentType {
  Osbal = 'osbal',
  Osdebt = 'osdebt',
  Renewal = 'renew',
  LiveChat = 'livechat',
}

export interface PaymentRequestDetails {
  amount: number;
  paymentType: PaymentType;
  fields:
    | RenewalPaymentFields
    | OsbalPaymentFields
    | OsdebtPaymentFields
    | LiveChatPaymentFields;
}

//
// Payment type fields.
//

export interface RenewalPaymentFields {
  ref: string;
  insurer: string;
  product: string;
  terms: string;
  renewdate: string;
  invitedate: string;
}

export interface OsbalPaymentFields {
  ref: string;
  product: string;
  terms: string;
  vuln: boolean;
  createdate: string;
}

export interface OsdebtPaymentFields {
  ref: string;
  product: string;
  terms: string;
  paid: boolean;
  createdate: string;
  expiredate: string;
}

export interface LiveChatPaymentFields {
  ref: string;
  product: string;
  created_at: string;
}
