export const defaultNumberFormats = {
  'en-GB': {
    currency: {
      style: 'currency',
      currency: 'GBP',
      notation: 'standard',
    },
  },
};

export const defaultLocaleMessages = {
  'en-GB': {
    payment: {
      heading: 'Payment due',
      ref_label: 'Reference',
      message: `Please make your payment by entering your credit or debit card details below.`,

      // Overrides...
      renewal: {
        heading: 'Your renewal price',
        heading_deposit: 'Your renewal deposit',
        ref_label: 'Broker ref',
        message_extra: `Before paying please ensure the information in your most recent invite email is correct. If you are unsure, please <a href="{ contactUrl }" rel="noopener" target="_blank">contact us</a>.`,
        // message_extra_link: '@.lower:contact_us',
      },
      debt: {
        heading: 'Outstanding balance',
        message_extra: `Need to speak with us? <a href="{ contactUrl }" rel="noopener" target="_blank">Contact us</a>.`,
        // message_extra_link: '@.capitalize:contact_us',
      },

      form: {
        email_field: 'Email Address',
        card_field: 'Credit/Debit Card',
        button_text: 'Pay {amount}',
      },

      checking_details: 'Checking your details...',
    },

    success: {
      heading: 'Your payment was successful',
      message: `Thank you for your payment. We will send through a confirmation email shortly.`,
      message_extra: '',

      // Overrides...
      renewal: {
        message_covered: `Your payment has been taken and you are now covered.
<br /><br />
We will update your policy, send an email confirmation and updated documents shortly.`,
        message_not_covered: `Your payment has been taken, however <strong>you are not covered</strong> until you receive your updated policy documents and confirmation via email.
<br /><br />
We aim to process your renewal as quickly as possible to ensure there is no gap in your cover.`,
        message_extra: `We will be in touch if we need any further information from you.`,
      },
      debt: {
        message: `We will update our records to reflect the payment being made.`,
      },
    },

    error: {
      heading: 'Sorry, we were unable to take your payment',
      message: 'An error occurred.',
      message_extra: '',

      // Overrides...
      notfound: {
        heading: 'You followed an invalid URL',
        message: "There's nothing else to show on this page, sorry!",
      },
      maintenance: {
        heading: "We'll be back soon",
        message: `We're performing some maintenance to this site at the moment and we'll be back online shortly.`,
        message_extra: `Please check back soon, or <a href="{ contactUrl }" rel="noopener" target="_blank">contact us</a> to make payment.`,
      },
      debt: {
        message: `This may be due to the link being expired, or issue with the payment system.`,
        message_extra: `Please feel free to try again, or <a href="{ contactUrl }" rel="noopener" target="_blank">contact us</a> to make payment.`,
      },
      renewal: {
        message: `This may be due to your invite being expired, an issue with the payment system, or an issue with loading your quote.`,
        message_extra: `Please feel free to try again, or <a href="{ contactUrl }" rel="noopener" target="_blank">contact us</a> to make payment.`,
      },
      livechat: {
        message: `This may be due to your payment link being expired or invalid, or an issue with our payments system.`,
        message_extra: `Please ask your support agent for a new payment link, or <a href="{ contactUrl }" rel="noopener" target="_blank">contact us</a>.`,
      },
    },
    contact: {
      opening_hours: 'Opening hours',
    },
    footer: {
      small_print: null,
      copyright: '© Copyright { brandName } { year }',
    },
    test_mode: 'Test mode',
    test_stripe_cards: 'Stripe test cards',
    contact_us: 'Contact us',
  },
};

export default defaultLocaleMessages;
