import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { computed, reactive, watch } from 'vue';
import { version as packageVersion } from '../../package.json';
import { useBrandsStore } from './brands';

const productionBuild = import.meta.env.MODE === 'production';

/**
 * Pinia store...
 *
 * @see https://pinia.vuejs.org/
 */
export const useDebugStore = defineStore('debug', () => {
  const { brandConfig } = storeToRefs(useBrandsStore());

  // Create a reactive object from local storage data.
  const persistentConfig = reactive(
    JSON.parse(window?.localStorage?.getItem('debugConfig')) ?? {}
  );

  // Automatically save the object when it's properties are updated.
  watch(persistentConfig, (value) => {
    window?.localStorage?.setItem('debugConfig', JSON.stringify(value));
  });

  const testing = computed((): boolean => {
    return !productionBuild;
  });

  const maintenance = computed((): boolean => {
    return brandConfig.value?.maintenance || false;
  });

  const buildVersion = computed(() => {
    return packageVersion;
  });

  const buildDate = computed(() => {
    return new Date(__BUILD_TIMESTAMP__);
  });

  // Persist XState debug setting between page loads.
  const enableStateDebug = computed({
    get(): boolean {
      return !!(testing.value && persistentConfig?.enableStateDebug);
    },

    set(newValue: boolean) {
      persistentConfig.enableStateDebug = newValue;
    },
  });

  return {
    persistentConfig,

    testing,
    maintenance,
    buildVersion,
    buildDate,

    enableStateDebug,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDebugStore, import.meta.hot));
}
