/**
 * @file Some utility functions for dealing with CSS/styling.
 */

import { keysToKebabCase } from '../utils';

/**
 * Checks a CSS value which can be em or px, and converts it to the pixel value
 * e.g. font-size: 18px; font-size: 1.125em;
 *
 * @param {string} value - The value we are checking
 * @returns {string} The value in pixels (px)
 */
export function toPx(value: string, baseSize: number = 16): string {
  if (value?.includes('px')) {
    return value;
  }

  if (value?.includes('em')) {
    const pxValue = parseInt(value, 10) * baseSize;
    return `${pxValue}px`;
  }

  // throw new Error('Could not convert value to px unit.');

  return value;
}

/**
 * Strips the 'px' from a value.
 *
 * Useful if a style property value needs to be given in as a plain number
 * without the 'px' unit.
 *
 * @param {string} value - The initial value
 * @returns {String} The value without its 'px' unit.
 *
 * @todo Unused, remove?
 */
export function stripPx(value) {
  if (value.includes('px')) {
    return value.replace('px', '');
  }

  return value;
}

/**
 * Gets the value of a CSS variable.
 *
 * @param {string} variable - The CSS variable
 * @returns {string} The value of the CSS variable
 *
 * @todo Unused, remove?
 */
export function cssVar(variable) {
  const documentStyles = getComputedStyle(document.documentElement);
  return documentStyles.getPropertyValue(variable).trim();
}

/**
 * Reformat theme styles into valid CSS variables.
 *
 * @see {@link https://github.com/jhildenbiddle/css-vars-ponyfill}
 */
export function getCssVarsFromStyles(themeStyles) {
  const kebab = keysToKebabCase(themeStyles);
  const cssTheme = {};

  Object.keys(kebab).forEach((key) => {
    Object.keys(kebab[key]).forEach((value) => {
      const cssVarKey = `--${key}-${value}`;
      cssTheme[cssVarKey] = kebab[key][value];
    });
  });

  return cssTheme;
}
