const brandKeys = [
  'somerset_bridge',
  'goskippy',
  'goskippy4business',
  'vavista',
  'debenhams',
];

const brandPatterns: { [key: string]: RegExp } = {
  somerset_bridge: /(\.*)somersetbridgelimited\.co\.uk/,
  goskippy: /(\.*)goskippy\.com/,
  vavista: /(\.*)vavista\.com/,
  debenhams: /(\.*)debenhamscarinsurance\.co\.uk/,
};

/**
 * Get the default brand if possible, try both Webpack and Vite environments.
 */
const defaultBrand: string | undefined = [
  /* eslint-disable */
  /* tslint:disable */

  // Webpack...
  (() => {
    try {
      return process.env.VUE_APP_DEFAULT_BRAND as string;
    } catch {
      return undefined;
    }
  })(),

  // Vite...
  (() => {
    try {
      // @ts-ignore
      return import.meta.env?.VITE_DEFAULT_BRAND as string;
    } catch {
      return undefined;
    }
  })(),

  /* eslint-enable */
  /* tslint:enable */
].find((el) => typeof el === 'string');

/**
 * Get a list of all known SBGL brand names.
 */
export function getKnownBrandKeys(): string[] {
  return brandKeys;
}

/**
 * Get the default brand name.
 *
 * The default brand is configured with environment var "VITE_DEFAULT_BRAND".
 *
 * @see /.env.example
 */
export function getDefaultBrandKey(): string | null {
  if (defaultBrand) {
    console.warn(`Using default brand: ${defaultBrand}`);

    return defaultBrand;
  }

  return null;
}

/**
 * Search for a brand that is relevant for the given URL.
 */
export function getBrandKeyFromUrl(location: string): string | null {
  const match = getKnownBrandKeys().find((index) => {
    const pattern = brandPatterns[index];

    if (pattern) {
      return location.match(pattern);
    }
  });

  if (match) {
    return match;
  }

  return null;
}

/**
 * Attempt to get the brand name from the current window URL, falling back to
 * the default if set.
 *
 * @throws Error if no brand is available.
 */
export function getBrandKeyFromWindow(): string {
  const { hostname } = window.location;
  const brand = getBrandKeyFromUrl(hostname) ?? getDefaultBrandKey();

  if (brand) {
    return brand;
  }

  throw new Error('No brand available.');
}
