/**
 * @file Errors specific to payments.
 */
import { ValidationError as JoiValidationError } from 'joi';

/**
 * @see https://stackoverflow.com/questions/41102060/typescript-extending-error-class
 */
abstract class BaseError extends Error {
  constructor(message?: string) {
    super(message);

    Object.setPrototypeOf(this, new.target.prototype);
    this.name = new.target.name;
  }
}

//

export class PaymentApiError extends BaseError {}

//

export interface ValidationErrorBag {
  [key: string]: string[];
}

export class PaymentValidationError extends BaseError {
  errorBag: ValidationErrorBag;

  constructor(errorBag: ValidationErrorBag) {
    super('The payment request details could not be validated');

    this.errorBag = errorBag;
  }

  /**
   * Create a PaymentValidationError from a JoiValidationError.
   */
  static fromJoiErrors(error: JoiValidationError) {
    const errorBag = transformJoiErrors(error);

    return new this(errorBag);
  }
}

// Used for testing.
export const exampleErrors = [
  new Error('Generic error'),
  new PaymentApiError('An API error occurred'),
  new PaymentValidationError({
    'fields.example': [
      'This condition failed',
      'Another condition failed',
      'A third condition failed',
    ],
    'fields.another': ['A condition failed for this field too'],
  }),
];

/**
 * Transform Joi errors into a bag we can use to create a PaymentValidationError.
 */
function transformJoiErrors(error: JoiValidationError): ValidationErrorBag {
  return Object.fromEntries(
    error.details.map((detail) => {
      return [detail.type, [detail.message]];
    })
  );
}
