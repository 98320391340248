<template>
  <ul class="footer-menu">
    <li v-for="({ title, href }, index) in footerMenuItems" :key="index">
      <a :href="href" rel="noopener" target="_blank">{{ title }}</a>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { useContentStore } from '@/stores/content';
import { storeToRefs } from 'pinia';

const { footerMenuItems } = storeToRefs(useContentStore());
</script>

<style lang="scss">
.footer-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  li {
    padding-bottom: 5px;

    a {
      color: var(--footer-link-color);
      transition: 0.3s ease all;

      &:hover,
      &:active,
      &:visited {
        text-decoration: var(--links-hover-text-decoration);
      }

      &:hover {
        color: var(--footer-link-hover-color);
      }
    }
  }

  :not(:last-child) {
    &:after {
      content: '|';
      color: var(--footer-link-color);
      padding-left: 1em;
      padding-right: 1em;
    }
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;

    :after {
      display: none;
    }
  }
}
</style>
