/**
 * @file Some SBG payments API specific getters and utilities.
 *
 * Uses brand configuration.
 */
import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useBrandsStore } from './brands';

/**
 * Pinia store...
 *
 * @see https://pinia.vuejs.org/
 */
export const useApiStore = defineStore('api', () => {
  const { brandConfig } = storeToRefs(useBrandsStore());

  const apiBaseUrl = computed((): string | undefined => {
    const override = import.meta.env?.VITE_API_OVERRIDE;

    if (override) {
      return override;
    }

    if (brandConfig.value) {
      const { apiUrl } = brandConfig.value;

      return apiUrl;
    }

    return undefined;
  });

  return {
    apiBaseUrl,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useApiStore, import.meta.hot));
}
