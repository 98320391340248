<template>
  <img v-if="brandLogo" class="brand-logo" :src="brandLogo" :alt="brandName" />
</template>

<script setup lang="ts">
import { useContentStore } from '@/stores/content';
import { storeToRefs } from 'pinia';

const content = storeToRefs(useContentStore());

const { brandName, brandLogo } = content;
</script>
