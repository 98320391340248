import { PaymentRequestDetails, PaymentType } from '@/sbgl/payments';
import { useStripeStore } from '@/stores/stripe';
import { Stripe, StripeElements, StripeError } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { assign, createMachine } from 'xstate';
import { log, sendParent } from 'xstate/lib/actions';
import { useApiStore } from '../../../stores/api';
import { V3ApiService } from '../api';
import { PaymentValidationError } from '../errors';

export interface ApiContext {
  brandKey?: string;

  stripeSdk?: Stripe;
  stripeElements?: StripeElements;
  apiService: V3ApiService;

  paymentType: PaymentType;
  paymentRequestData?: PaymentRequestDetails;

  returnUrl?: string;
  paymentIntent?: string;
  paymentIntentClientSecret?: string;
}

export type ApiEvent =
  | {
      type: 'STRIPE_SETUP_NEW_PAYMENT';
      paymentRequestData: PaymentRequestDetails;
      returnUrl: string;
    }
  | {
      type: 'STRIPE_SETUP_EXISTING_PAYMENT';
      paymentType: PaymentType;
      paymentIntent: string;
      paymentIntentClientSecret: string;
      redirectStatus: string;
      returnUrl: string;
    }
  | {
      type: 'STRIPE_REQUEST_DETAILS_VALID';
      validated: object;
    }
  | {
      type: 'STRIPE_REQUEST_DETAILS_INVALID';
      error: PaymentValidationError;
    }
  | {
      type: 'STRIPE_INTENT_CREATED';
      paymentIntent: string;
      paymentIntentClientSecret: string;
    }
  | {
      type: 'STRIPE_API_SERVICE_LOADED';
      apiService: V3ApiService;
    }
  | {
      type: 'STRIPE_SDK_LOADED';
      stripeSdk: Stripe;
    }
  | {
      type: 'STRIPE_ELEMENTS_LOADED';
      stripeElements: StripeElements;
    }
  | {
      type: 'STRIPE_FORM_READY';
    }
  | {
      type: 'STRIPE_FORM_CONFIRMING';
    }
  | {
      type: 'STRIPE_PAYMENT_CAPTURED';
      paymentIntent: string;
    }
  | {
      type: 'STRIPE_PAYMENT_REQUIRES_ACTION';
      paymentIntent: string;
    }
  | {
      type: 'STRIPE_PAYMENT_SUCCEEDED';
      paymentIntent: string;
    }
  | {
      type: 'STRIPE_PAYMENT_FAILED';
      paymentIntent?: string;
      error?: Error;
    };

/**
 * An XState state machine describing the SBGL payments API capture flow.
 *
 * @see https://xstate.js.org/docs/
 */
export const v3PaymentMachine = createMachine<ApiContext, ApiEvent>(
  {
    id: 'stripe',
    initial: 'loading',
    predictableActionArguments: true,
    strict: true,

    context: {
      brandKey: undefined,

      stripeSdk: undefined,
      stripeElements: undefined,
      apiService: undefined,

      paymentType: undefined,
      paymentRequestData: undefined,

      returnUrl: undefined,
      paymentIntent: undefined,
      paymentIntentClientSecret: undefined,
    },

    states: {
      // Setting up Stripe SDK and SBG payments API service...
      loading: {
        always: [
          // Move to next state when both have been set up.
          {
            target: 'ready',
            cond: (context) => {
              return !!(context.apiService && context.stripeSdk);
            },
          },
        ],

        on: {
          STRIPE_API_SERVICE_LOADED: {
            actions: [
              assign({
                apiService: (context, event) => event.apiService,
              }),
            ],
          },
          STRIPE_SDK_LOADED: {
            actions: [
              assign({
                stripeSdk: (context, event) => event.stripeSdk,
              }),
            ],
          },
          STRIPE_PAYMENT_FAILED: {
            target: 'failure',
            actions: [
              log(
                (context, event) => `Could not setup Stripe SDK or API service`
              ),
              sendParent((context, event) => ({
                type: 'PAYMENT_ERROR',
                error: event.error,
              })),
            ],
          },
        },

        invoke: [
          // Set up the SBG payments API service class.
          {
            src: (context, event) => (send, onReceive) => {
              const { brandKey } = context;
              const { apiBaseUrl } = useApiStore();

              try {
                const apiService = new V3ApiService(apiBaseUrl, brandKey);

                send({
                  type: 'STRIPE_API_SERVICE_LOADED',
                  apiService,
                });
              } catch (error) {
                send({
                  type: 'STRIPE_PAYMENT_FAILED',
                  error,
                });
              }
            },
          },

          // Set up the Stripe.js browser SDK.
          {
            src: (context, event) => (send, onReceive) => {
              // Get the public API key for this brand.
              const { publishableApiKey } = useStripeStore();

              console.info('Loading Stripe instance...');

              // Load Stripe SDK instance.
              loadStripe(publishableApiKey)
                .then((stripeSdk) => {
                  send({
                    type: 'STRIPE_SDK_LOADED',
                    stripeSdk,
                  });
                })
                .catch((error) => {
                  send({
                    type: 'STRIPE_PAYMENT_FAILED',
                    error,
                  });
                });
            },
          },
        ],
      },

      // Ready to start or resume a payment...
      ready: {
        entry: [
          sendParent({
            type: 'INIT_API_READY',
          }),
        ],

        on: {
          STRIPE_SETUP_NEW_PAYMENT: {
            target: 'validatingRequestDetails',
            actions: [
              log((context, event) => `Setting up new payment...`),
              assign((context, event) => {
                const { getReturnUrl } = useStripeStore();

                const { paymentRequestData } = event;
                const { paymentType } = paymentRequestData;

                return {
                  paymentType,
                  paymentRequestData,
                  returnUrl: getReturnUrl(paymentType),
                };
              }),
            ],
          },

          STRIPE_SETUP_EXISTING_PAYMENT: {
            target: 'continuingFromSecret',
            actions: [
              log((context, event) => `Resuming existing payment...`),
              assign((context, event) => {
                const { getReturnUrl } = useStripeStore();

                const {
                  paymentType,
                  paymentIntent,
                  paymentIntentClientSecret,
                  redirectStatus,
                } = event;

                return {
                  paymentType,
                  paymentIntent,
                  paymentIntentClientSecret,
                  redirectStatus,
                  returnUrl: getReturnUrl(paymentType),
                };
              }),
            ],
          },
        },
      },

      // Checking new payment details...
      validatingRequestDetails: {
        on: {
          STRIPE_REQUEST_DETAILS_VALID: {
            target: 'creatingPaymentIntent',
          },
          STRIPE_REQUEST_DETAILS_INVALID: {
            target: 'failure',
            actions: [
              log((context, event) => `Payment request details are not valid.`),
              sendParent((context, event) => ({
                type: 'PAYMENT_ERROR',
                error: event.error,
              })),
            ],
          },
          STRIPE_PAYMENT_FAILED: {
            target: 'failure',
            actions: [
              log((context, event) => `Could not validate payment request.`),
              sendParent((context, event) => ({
                type: 'PAYMENT_ERROR',
                error: event.error,
              })),
            ],
          },
        },

        invoke: {
          src: (context, event) => (send, onReceive) => {
            const { apiService, paymentRequestData } = context;

            // Make server side check via API...
            const request = apiService.checkPaymentFields(paymentRequestData);

            request
              .then((data) => {
                const { validated } = data;

                send({
                  type: 'STRIPE_REQUEST_DETAILS_VALID',
                  validated,
                });
              })
              .catch((error) => {
                if (error instanceof PaymentValidationError) {
                  send({
                    type: 'STRIPE_REQUEST_DETAILS_INVALID',
                    error,
                  });

                  return;
                }

                send({
                  type: 'STRIPE_PAYMENT_FAILED',
                  error,
                });
              });
          },
        },
      },

      // Creating a Stripe intent using the payment request details...
      creatingPaymentIntent: {
        on: {
          STRIPE_INTENT_CREATED: {
            target: 'handlingUserInput',
            actions: assign((context, event) => {
              const { paymentIntent, paymentIntentClientSecret } = event;

              return {
                paymentIntent,
                paymentIntentClientSecret,
              };
            }),
          },
          STRIPE_PAYMENT_FAILED: {
            target: 'failure',
            actions: [
              log(
                (context, event) => `Could not create inital payment intent.`
              ),
              sendParent((context, event) => ({
                type: 'PAYMENT_ERROR',
                error: event.error,
              })),
            ],
          },
        },

        invoke: {
          src: (context, event) => (send, onReceive) => {
            const { apiService, paymentRequestData } = context;

            const request = apiService.startEmptyPayment(paymentRequestData);

            request
              .then((data) => {
                const { paymentIntent, paymentIntentClientSecret } = data;

                // The payment intent will be in the "requires_payment_method" state.
                //
                // A payment method and other details will need to be added
                // later before confirming the payment intent.
                send({
                  type: 'STRIPE_INTENT_CREATED',
                  paymentIntent,
                  paymentIntentClientSecret,
                });
              })
              .catch((error) => {
                send({
                  type: 'STRIPE_PAYMENT_FAILED',
                  error,
                });
              });
          },
        },
      },

      // Retrieving a previously created Stripe payment intent using client secret...
      continuingFromSecret: {
        invoke: {
          src: async (context, event) => {
            const { stripeSdk, paymentIntentClientSecret } = context;

            // See: https://stripe.com/docs/js/payment_intents/retrieve_payment_intent
            const { paymentIntent, error } =
              await stripeSdk.retrievePaymentIntent(paymentIntentClientSecret);

            if (error) {
              throw error;
            }

            return {
              paymentIntent,
            };
          },
          onDone: [
            {
              target: 'attemptPaymentCapture',
            },
          ],
          onError: [
            {
              target: 'failure',
              actions: [
                log(
                  (context, event) =>
                    `Could not resume existing payment intent.`
                ),
                sendParent((context, event) => ({
                  type: 'PAYMENT_ERROR',
                  error: event.data,
                })),
              ],
            },
          ],
        },
      },

      // Setting up and displaying Stripe Elements form...
      handlingUserInput: {
        entry: [
          sendParent((context, event) => ({
            type: 'PAYMENT_FORM_LOADING',
          })),
        ],

        on: {
          STRIPE_ELEMENTS_LOADED: {
            actions: [
              assign({
                stripeElements: (context, event) => event.stripeElements,
              }),
            ],
          },

          STRIPE_FORM_READY: {
            actions: [
              sendParent((context, event) => ({
                type: 'PAYMENT_FORM_READY',
              })),
            ],
          },

          STRIPE_FORM_CONFIRMING: {
            target: 'attemptClientConfirm',
          },
        },
      },

      // Attempting to confirm the payment intent using the Stripe browser SDK.
      attemptClientConfirm: {
        invoke: {
          src: async (context, event) => {
            const { stripeElements, stripeSdk, returnUrl } = context;

            // See: https://stripe.com/docs/js/payment_intents/confirm_payment
            const { error: confirmError, paymentIntent } =
              await stripeSdk.confirmPayment({
                elements: stripeElements,
                confirmParams: {
                  return_url: returnUrl,
                },
                redirect: 'if_required',
              });

            if (confirmError) {
              console.error(confirmError);

              throw confirmError;
            }

            return { paymentIntent };
          },
          onDone: [
            {
              target: 'attemptPaymentCapture',
            },
          ],
          onError: [
            {
              target: 'handlingUserInput', // Allow a different method to be used.
              actions: [
                (context, event) => {
                  const error = event.data as StripeError;

                  // Show warning message to customer.
                  alert(error.message);
                },
                // But log the error anyway...
                sendParent((context, event) => ({
                  type: 'PAYMENT_ERROR',
                  error: event.data,
                })),
              ],
            },
          ],
        },
      },

      // Attempting to complete the payment using the SBG payments API...
      attemptPaymentCapture: {
        entry: [
          sendParent((context, event) => ({
            type: 'PAYMENT_PROCESSING',
          })),
        ],

        on: {
          STRIPE_PAYMENT_SUCCEEDED: {
            target: 'success',
            actions: [log((context, event) => `Completed payment.`)],
          },
          STRIPE_PAYMENT_REQUIRES_ACTION: {
            target: 'handleClientInteraction',
            actions: [log((context, event) => `Payment requires action.`)],
          },
          STRIPE_PAYMENT_FAILED: {
            target: 'failure',
            actions: [
              log((context, event) => `Could not complete payment.`),
              sendParent((context, event) => ({
                type: 'PAYMENT_ERROR',
                error: event.error,
              })),
            ],
          },
        },

        invoke: {
          src: (context, event) => (send, onReceive) => {
            const { apiService, paymentType, returnUrl, paymentIntent } =
              context;

            const request = apiService.continueExistingPayment(
              paymentType,
              paymentIntent,
              {
                return_url: returnUrl,
              }
            );

            request
              .then((data) => {
                const { success, requiresAction } = data;

                if (success) {
                  return send({
                    type: 'STRIPE_PAYMENT_SUCCEEDED',
                    paymentIntent,
                  });
                } else if (requiresAction) {
                  return send({
                    type: 'STRIPE_PAYMENT_REQUIRES_ACTION',
                    paymentIntent,
                  });
                } else {
                  return send({
                    type: 'STRIPE_PAYMENT_FAILED',
                    paymentIntent,
                  });
                }
              })
              .catch((error) => {
                send({
                  type: 'STRIPE_PAYMENT_FAILED',
                  paymentIntent,
                  error,
                });
              });
          },
        },
      },

      // Using Stripe.js browser SDK to handle user interaction... (e.g. 3DSecure)
      handleClientInteraction: {
        invoke: {
          src: async (context, event) => {
            const { stripeSdk, paymentIntentClientSecret } = context;

            // See: https://stripe.com/docs/js/payment_intents/handle_card_action
            const { error, paymentIntent } = await stripeSdk.handleNextAction({
              clientSecret: paymentIntentClientSecret,
            });

            if (error) {
              throw error;
            }

            return { paymentIntent };
          },
          onDone: [
            {
              target: 'attemptPaymentCapture',
              actions: assign({
                paymentIntent: (context, event) => event.data,
              }),
            },
          ],
          onError: [
            {
              target: 'failure',
              actions: [
                sendParent((context, event) => ({
                  type: 'PAYMENT_ERROR',
                  error: event.data,
                })),
              ],
            },
          ],
        },
      },

      // The payment was captured...
      success: {
        type: 'final',

        entry: [
          sendParent((context, event) => ({
            type: 'PAYMENT_SUCCEEDED',
            paymentIntent: context.paymentIntent,
          })),
        ],
      },

      // An unrecoverable error occured...
      failure: {
        type: 'final',

        entry: [
          sendParent((context, event) => ({
            type: 'PAYMENT_FAILED',
            paymentIntent: context.paymentIntent,
          })),
        ],
      },
    },
  },

  {
    guards: {},
    services: {},
  }
);
