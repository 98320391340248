/**
 * @file Common API functionality used across versions.
 */

import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { PaymentApiError } from '../errors';

export abstract class BaseApiService {
  protected axiosInstance: AxiosInstance;

  protected baseUrl: string;
  protected brandKey: string;

  constructor(baseUrl: string, brandKey: string) {
    if (!baseUrl || !brandKey) {
      throw new PaymentApiError('Could not create payment API service');
    }

    this.baseUrl = baseUrl;
    this.brandKey = brandKey;

    console.info('Created API service...', { brandKey, baseUrl });

    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: { ...this.buildDefaultRequestHeaders() },
    });

    // Check both successful and failed responses are for the expected brand.
    this.axiosInstance.interceptors.response.use(
      (response) => this.checkResponseBrand(response),
      (error) => {
        this.checkResponseBrand(error.response);

        return Promise.reject(error);
      }
    );
  }

  /**
   * Set up headers used across all API requests.
   */
  protected buildDefaultRequestHeaders() {
    return {
      'Platform-Brand': this.brandKey,
      'Content-Type': 'application/json',
    };
  }

  /**
   * Check the Platform-Brand response header matches the expected brand.
   */
  protected checkResponseBrand(response: AxiosResponse): AxiosResponse {
    const requestBrand = this.brandKey;
    const responseBrand = response.headers['platform-brand'];

    if (responseBrand) {
      if (responseBrand !== requestBrand) {
        throw new PaymentApiError(
          `Response brand "${responseBrand}" is different to request brand "${requestBrand}"`
        );
      }
    } else {
      console.warn(`Response does not have a Platform-Brand header`);
    }

    return response;
  }
}
