<template>
  <div v-if="activeBrand" class="wrapper" :class="brandClasses">
    <PageHeader />
    <TestModeBanner v-if="testing" />

    <main class="page-main">
      <Maintenance v-if="true === maintenance" />
      <RouterView v-else v-slot="{ Component }">
        <Transition name="slide-fade" mode="out-in">
          <KeepAlive>
            <component :is="Component" />
          </KeepAlive>
        </Transition>
      </RouterView>
      <Loader :style="{ visibility: loadingVisibility }" />
    </main>

    <PageFooter />
  </div>
</template>

<script setup lang="ts">
import Loader from '@/components/Loader.vue';
import PageFooter from '@/components/footer/PageFooter.vue';
import PageHeader from '@/components/header/PageHeader.vue';
import TestModeBanner from '@/components/header/TestModeBanner.vue';
import { i18n } from '@/locale';
import { getBrandKeyFromWindow } from '@/sbgl/brand';
import { useBrandsStore } from '@/stores/brands';
import { useContentStore } from '@/stores/content';
import { useDebugStore } from '@/stores/debug';
import { usePaymentStore } from '@/stores/payment';
import { useThemeStore } from '@/stores/theme';
import Maintenance from '@/views/Maintenance.vue';
import { useActor } from '@xstate/vue';
import cssVars from 'css-vars-ponyfill';
import { storeToRefs } from 'pinia';
import { computed, onErrorCaptured, onMounted, watch } from 'vue';
import { RouterView, useRouter } from 'vue-router';
import { setupSentryErrorCapture } from './sentry';

const brands = useBrandsStore();
const { switchBrand } = brands;
const { active: activeBrand } = storeToRefs(brands);
const { brandName } = storeToRefs(useContentStore());

const { testing, maintenance, enableStateDebug } = storeToRefs(useDebugStore());

// Get loading status by current state.
const loading = computed(() => {
  const { state } = storeToRefs(usePaymentStore());

  return state.value.hasTag('loading');
});

// The visibility CSS property value for the loading spinner.
const loadingVisibility = computed(() => {
  return loading.value ? 'visible' : 'hidden';
});

//
const brandClasses = computed(() => {
  const key = activeBrand.value?.brandKey;

  return {
    [key]: true,
  };
});

// Apply updates when brand is changed.
watch(activeBrand, (brand) => {
  applyBrandHeadMeta();
  applyBrandLocaleOverrides();
  applyBrandStyles();

  // Check if another brand has already been used to set up the state machines.
  const { state } = usePaymentStore();

  if (state.context.brandKey && state.context.brandKey !== brand.brandKey) {
    alert(
      `The brand was changed after setting up the payment details. You may encounter unexpected behavior.`
    );
  }
});

//
setupExitHandler();

// Log errors to Sentry.
if (import.meta.env.MODE !== 'production') {
  setupSentryErrorCapture();
}

// Setup brand...
const initialBrand = getBrandKeyFromWindow();
const brandReady = switchBrand(initialBrand);

//
onMounted(async () => {
  await Promise.all([
    // Wait for brand to be loaded.
    brandReady,

    // Add additional delay when debugging state to avoid missing early events.
    enableStateDebug.value &&
      new Promise((resolve) => {
        setTimeout(resolve, 1000);
      }),
  ]);

  // Send initial event after brand has loaded.
  const { send } = usePaymentStore();

  send({
    type: 'INIT_LOADING',
    brandKey: activeBrand.value.brandKey,
  });
});

// Redirect to error page if an unhandled error occurs.
onErrorCaptured((error, instance, info) => {
  console.error(error);

  // Forward the error into the top level state machine.
  try {
    const { send } = usePaymentStore();

    send({
      type: 'PAYMENT_ERROR',
      error,
    });
  } catch (error) {
    // Another error prevented the original being sent to the journey machine.
  }

  const router = useRouter();

  // TODO: Will still show loading spinner as "PAYMENT_ERROR" event does not transition machine to failure state.
  // Do we want to send "PAYMENT_FAILED", making all unhandled errors unrecoverable?
  router.replace('error');
});

/**
 * Injects meta, title and favicons into <head>
 *
 * Favicon and meta info based on favicon installation instructions at
 * @see {@link https://realfavicongenerator.net/}
 * @todo Add some additional checks by reading file system and checking if files exist. In the mean time,
 * just make sure all favicons are present!
 *
 */
function applyBrandHeadMeta() {
  const { fontImportUrls, headMetaAttrs, headFaviconLinks } = useThemeStore();

  const head = document.getElementsByTagName('head')[0];

  headMetaAttrs.forEach((f) => {
    const keys = Object.keys(f);
    const values = Object.values(f);
    const link = document.createElement('link');

    for (let i = 0; i < keys.length; i++) {
      link[keys[i]] = values[i];
    }

    head.appendChild(link);
  });

  headFaviconLinks.forEach((m) => {
    const keys = Object.keys(m);
    const values = Object.values(m);
    const meta = document.createElement('meta');

    for (let i = 0; i < keys.length; i++) {
      meta[keys[i]] = values[i];
    }

    head.appendChild(meta);
  });

  document.title = `${brandName.value} | Payments`;

  // Load custom font urls...
  fontImportUrls.forEach((url) => {
    const fontStyle = document.createElement('link');
    fontStyle.href = url;
    fontStyle.rel = 'stylesheet';

    head.appendChild(fontStyle);
  });
}

/**
 * Apply text translation overrides for the active brand.
 */
function applyBrandLocaleOverrides() {
  const { activeKey } = useBrandsStore();

  // Get the locale language override key for the active brand.
  i18n.global.locale.value = activeKey ? `en-GB-${activeKey}` : `en-GB`;
}

/**
 * Set CSS variables from brand theme styles.
 */
function applyBrandStyles() {
  const { stylesAsCssProperties } = useThemeStore();

  cssVars({
    variables: stylesAsCssProperties,
    onFinally(hasChanged) {
      if (hasChanged) {
        console.info(`Updated CSS variables with brand styles`);
      }
    },
  });
}

/**
 * Show warning upon leaving page, when appropriate.
 */
function setupExitHandler() {
  window.addEventListener('beforeunload', (event) => {
    const { state } = usePaymentStore();
    const { state: stripeState } = useActor(state.context.stripeMachineRef);

    // Don't show warning...
    const allowExit =
      // ...when user journey has ended.
      state.done ||
      // ...when Stripe may attempt to redirect when processing the payment.
      stripeState.value.matches('attemptClientConfirm');

    // Check if in progress...
    if (!allowExit) {
      // Cancel exit, show warning.
      event.preventDefault();
      event.returnValue = `Are you sure you want to leave and lose your progress?`;
    }
  });
}
</script>

<style lang="scss">
@use '@/css/app.scss';

html,
body {
  height: 100%;
}

#app,
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-main {
  flex: 1 0 auto;
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: var(--colors-light);
}

.page-header,
.page-footer {
  flex-shrink: 0;
}
</style>
