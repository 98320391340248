import { PaymentType } from '@/sbgl/payments';
import { createRouter, createWebHistory } from 'vue-router';
import { usePaymentStore } from './stores/payment';

const base = import.meta.env.BASE_URL;

export const router = createRouter({
  history: createWebHistory(base),
  routes: [
    {
      path: '/renew',
      name: 'renew',
      component: () => import('./views/Entry.vue'),
      meta: {
        paymentType: PaymentType.Renewal,
        allowedStates: ['idle'],
      },
    },
    {
      path: '/osbal',
      name: 'osbal',
      component: () => import('./views/Entry.vue'),
      meta: {
        paymentType: PaymentType.Osbal,
        allowedStates: ['idle'],
      },
    },
    {
      path: '/osdebt',
      name: 'osdebt',
      component: () => import('./views/Entry.vue'),
      meta: {
        paymentType: PaymentType.Osdebt,
        allowedStates: ['idle'],
      },
    },
    {
      path: '/livechat',
      name: 'livechat',
      component: () => import('./views/Entry.vue'),
      meta: {
        paymentType: PaymentType.LiveChat,
        allowedStates: ['idle'],
      },
    },
    {
      path: '/payment',
      name: 'payment',
      component: () => import('./views/Payment.vue'),
      props: true, // takes params as props
      meta: {
        allowedStates: ['loadingPaymentForm', 'customerEnteringDetails'],
      },
    },
    {
      path: '/payment/resume/:paymentType',
      name: 'resume',
      component: () => import('./views/Resume.vue'),
      meta: {
        allowedStates: ['idle'],
      },
    },
    {
      path: '/payment/complete',
      name: 'complete',
      component: () => import('./views/Complete.vue'),
      props: true,
      meta: {
        allowedStates: ['success'],
      },
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: () => import('./views/Maintenance.vue'),
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('./views/Error.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('./views/NotFound.vue'),
    },
  ],
});

export default router;

// Check state is allowed for route.
router.beforeEach((to, from) => {
  if (to.meta.allowedStates) {
    const { allowedStates } = to.meta;
    const { send, state } = usePaymentStore();

    if (allowedStates.some(state.matches)) {
      return true;
    }

    // TODO: Add proper error type?
    const error = new Error('Unexpected state for route');
    error.currentState = state.value;
    error.allowedStates = allowedStates;

    send({
      type: 'PAYMENT_ERROR',
      error,
    });

    // TODO: Do we want to send "PAYMENT_FAILED"?
    return { name: 'error' };
  }
});

router.afterEach(() => {});
