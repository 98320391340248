import { inspect } from '@xstate/inspect';
import { useDebugStore } from './stores/debug';
import { usePaymentStore } from './stores/payment';

export const stateDebugPlugin = {
  install(app, options) {
    const { testing, enableStateDebug } = useDebugStore();

    // TODO: This check occurs before brand config is loaded.
    if (!testing || !enableStateDebug) {
      return;
    }

    console.info('Setting up XState debugging...');

    // Show debug tools in new tab.
    if (typeof window !== 'undefined') {
      inspect({
        iframe: false,
      });
    }

    // Log state transitions and events to console.
    setupStateListeners();
  },
};

/**
 * Register XState debug event listeners.
 */
function setupStateListeners() {
  const { service } = usePaymentStore();

  service.onEvent((event) => {
    console.log('Event', { ...event });
  });

  service.onTransition((transition) => {
    console.log('Transition', { ...transition });
  });
}
