import {
  PaymentRequestDetails,
  PaymentType,
  StripePaymentIntent,
  StripePaymentMethod,
} from '..';
import { PaymentValidationError, ValidationErrorBag } from '../errors';
import { BaseApiService } from './base';

/**
 * API version 3...
 */
export class V3ApiService extends BaseApiService {
  /**
   * Check the fields for a given payment request are valid.
   */
  async checkPaymentFields(paymentRequestData: PaymentRequestDetails) {
    const { paymentType, fields } = paymentRequestData;

    const requestData = {
      fields: {
        ...fields,
      },
    };

    const response = await this.axiosInstance
      .post(`/v3/pay/${paymentType}/check`, requestData)
      .catch((error) => {
        if (error.response?.status === 422) {
          // Validation error...
          const errorBag = error.response.data as ValidationErrorBag;

          throw new PaymentValidationError(errorBag);
        }

        // Other error...
        console.error('Could not check fields payment fields.', { error });

        throw error;
      });

    const { validated } = response.data;

    return { validated };
  }

  /**
   * Start a payment with user details and Stripe PaymentMethod ID.
   *
   * The server will attempt to automatically capture the payment if possible,
   * so this call may result in immediate success.
   *
   * @deprecated
   */
  async startNewPayment(
    requestDetails: PaymentRequestDetails,
    paymentMethodId: StripePaymentMethod,
    additional = {}
  ) {
    const { amount, paymentType, fields } = requestDetails;

    // Build request data.
    const requestData = {
      ...additional,
      amount,
      payment_method_id: paymentMethodId,
      fields: {
        ...fields,
      },
    };

    const response = await this.axiosInstance
      .post(`/v3/pay/${paymentType}/stripe/start`, requestData)
      .catch((error) => {
        if (error.response?.status === 422) {
          // Validation error...
          const errorBag = error.response.data as ValidationErrorBag;

          throw new PaymentValidationError(errorBag);
        }

        // Other error...
        console.error('Could not start payment.', { error });

        throw error;
      });

    const {
      message,
      payment_intent_client_secret: paymentIntentClientSecret,
      payment_intent_id: paymentIntent,
      requires_action: requiresAction,
      success,
    } = response.data;

    return {
      message,
      paymentIntent,
      paymentIntentClientSecret,
      requiresAction,
      success,
    };
  }

  /**
   * Create a payment without user details and payment method.
   */
  async startEmptyPayment(
    requestDetails: PaymentRequestDetails,
    additional = {}
  ) {
    const { amount, paymentType, fields } = requestDetails;

    // Build request data.
    const requestData = {
      ...additional,
      amount,
      fields: {
        ...fields,
      },
    };

    const response = await this.axiosInstance
      .post(`/v3/pay/${paymentType}/stripe/start/empty`, requestData)
      .catch((error) => {
        if (error.response?.status === 422) {
          // Validation error...
          const errorBag = error.response.data as ValidationErrorBag;

          throw new PaymentValidationError(errorBag);
        }

        // Other error...
        console.error('Could not start payment.', { error });

        throw error;
      });

    const {
      message,
      payment_intent_client_secret: paymentIntentClientSecret,
      payment_intent_id: paymentIntent,
      requires_action: requiresAction,
      success,
    } = response.data;

    return {
      message,
      paymentIntent,
      paymentIntentClientSecret,
      requiresAction,
      success,
    };
  }

  /**
   * Continue an existing payment using a Stripe PaymentIntent ID.
   */
  async continueExistingPayment(
    paymentType: PaymentType,
    paymentIntentId: StripePaymentIntent,
    additional = {}
  ) {
    // Build request data.
    const requestData = {
      ...additional,
      payment_intent_id: paymentIntentId,
    };

    const response = await this.axiosInstance
      .post(`/v3/pay/${paymentType}/stripe/continue`, requestData)
      .catch((error) => {
        if (error.response?.status === 422) {
          // Validation error...
          const errorBag = error.response.data as ValidationErrorBag;

          throw new PaymentValidationError(errorBag);
        }

        // Other error...
        console.error('Could not continue payment.', { error });

        throw error;
      });

    const {
      message,
      payment_intent_client_secret: paymentIntentClientSecret,
      payment_intent_id: paymentIntent,
      requires_action: requiresAction,
      success,
    } = response.data;

    return {
      message,
      paymentIntent,
      paymentIntentClientSecret,
      requiresAction,
      success,
    };
  }
}
