<template>
  <div class="card">
    <slot />
  </div>
</template>

<style lang="scss">
.card {
  position: relative;

  width: 100%;
  max-width: 34rem;
  height: auto;
  margin: 6rem auto;
  padding: 3.5rem;

  font-size: var(--typography-font-size-large);
  border-radius: 1.5rem;
  box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1);
  background: var(--colors-white);

  @media screen and (max-width: 992px) {
    padding: 2rem;
    margin: 1.5rem auto;

    font-size: var(--typography-font-size);
  }
}
</style>
