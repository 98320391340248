import { createI18n } from 'vue-i18n';
import { brandLocaleMessages } from './brands';
import { defaultLocaleMessages, defaultNumberFormats } from './defaults';

/**
 * Use Vue I18n to provide translatable text strings per brand.
 */
export const i18n = createI18n({
  legacy: false,
  locale: 'en-GB',
  fallbackLocale: ['en-GB', 'en'],
  messages: {
    ...defaultLocaleMessages,
    ...brandLocaleMessages,
  },
  numberFormats: {
    ...(defaultNumberFormats as any),
  },
});

export default i18n;
