export const brandLocaleMessages = {
  'en-GB-somerset_bridge': {
    footer: {
      small_print:
        'Registered in England and Wales (No. 6334001) Registered address: Lysander House, Catbrain Lane, Cribbs Causeway, Bristol, BS10 7TQ. VAT number 927 491 007. Somerset Bridge Insurance Services Limited are authorised and regulated by the Financial Conduct Authority (FCA) Firm Reference Number 477112.',
    },
  },
  'en-GB-goskippy': {
    error: {
      notfound: {
        heading:
          "What's that, Skippy? The kids are trapped down the old mine-shaft again?",
        message: "There's nothing else to show on this page, sorry!",
      },
    },
    footer: {
      small_print:
        'GoSkippy Insurance is arranged and administered by Somerset Bridge Insurance Services Ltd who are authorised and regulated by the Financial Conduct Authority (Firm Reference Number 477112). Registered in England and Wales (No. 6334001). Registered office: Lysander House, Catbrain Lane, Cribbs Causeway, Bristol, BS10 7TQ.',
    },
  },
  'en-GB-goskippy4business': {
    footer: {
      small_print:
        'GoSkippy Insurance is arranged and administered by Somerset Bridge Insurance Services Ltd who are authorised and regulated by the Financial Conduct Authority (Firm Reference Number 477112). Registered in England and Wales (No. 6334001). Registered office: Lysander House, Catbrain Lane, Cribbs Causeway, Bristol, BS10 7TQ.',
    },
  },
  'en-GB-vavista': {
    footer: {
      small_print:
        'Vavista is a trading name of Somerset Bridge Insurance Services Ltd. Insurance is arranged and administered by Somerset Bridge Insurance Services Ltd who are authorised and regulated by the Financial Conduct Authority (Firm Reference Number 477112). Registered in England and Wales (No. 6334001). Registered office: Lysander House, Catbrain Lane, Cribbs Causeway, Bristol, BS10 7TQ.',
    },
  },
  'en-GB-debenhams': {
    footer: {
      small_print:
        "Registered Office 10 Brock Street, Regent's Place, London, NW1 3FG. Registered in England and Wales. Number: 00083395.",
    },
  },
};

export default brandLocaleMessages;
